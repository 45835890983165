.event-table {
  width: 100%;
  border-collapse: collapse;
}

.event-table th, .event-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.even-row {
  background-color: #f2f2f2;
}

.odd-row {
  background-color: #fff;
}
